







































































import { Component, Vue } from 'vue-property-decorator'
import sidebarModule from '@/store/sidebar'
import { RouteNames } from '@/router'
import { cart } from '@/store/cart/cartModule'
import terminalModule from '@/store/terminal'
import PurchaseCancelAlert from '@/components/PurchaseCancelAlert.vue'

@Component({
  components: {
    PurchaseCancelAlert
  }
})
export default class Header extends Vue {
  readonly RouteNames = RouteNames

  get isShowBackBtn() {
    return this.$route.name !== RouteNames.payment
  }

  back():void {
    this.$router.back()
  }

  get isOpenSidebar(): boolean {
    return sidebarModule.state.open
  }

  get itemsInCart() {
    return cart.items?.length
  }

  get isDemoMode() {
    return terminalModule.getters.terminal?.isDemoMode
  }

  toggleSidebar():void {
    sidebarModule.mutations.setOpen(!this.isOpenSidebar)
  }

  logoClickHandler() {
    if (this.isDemoMode) {
      this.$router.push({
        name: RouteNames.catalog
      })
    } else if (this.itemsInCart) {
      this.$bvModal.show('purchase-cancel-alert-header')
    } else {
      this.goToIndexPage()
    }
  }

  goToIndexPage() {
    this.$router.push({
      name: RouteNames.index
    })
  }
}
