import { createModule } from 'vuexok'
import store from '.'
import { logBreadcrumb } from '@/core/logger'
const tag = 'sidebarModule'
export const sidebarModule = createModule(tag, {
  namespaced: true,
  state: {
    open: false
  },
  mutations: {
    setOpen(state, open: boolean) {
      logBreadcrumb({ tag }, 'setOpen')
      state.open = open
    }
  }
})

sidebarModule.register(store)

export default sidebarModule
