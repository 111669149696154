




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BButton, BModal } from 'bootstrap-vue'
import { RouteNames } from '@/router'
import Sentry from '@/plugins/sentry'

@Component({
  components: {
    BButton,
    BModal
  }
})
export default class PurchaseCancelAlert extends Vue {
  @Prop({ type: String })
  id!:string

  @Prop({ type: Array })
  buttonText?: string[]

  timer: null | ReturnType<typeof setTimeout> = null

  onButtonClick(text: string) {
    Sentry.captureMessage(text)
    this.$emit('button-clicked')
    this.$bvModal.hide(this.id)
  }

  mounted():void {
    this.$root.$on('bv::modal::show', this.startTimer)
    this.$root.$on('bv::modal::hide', this.stopTimer)
  }

  beforeDestroy():void {
    this.$root.$off('bv::modal::show', this.startTimer)
    this.$root.$off('bv::modal::hide', this.stopTimer)
  }

  startTimer(bvEvent: Event, id: string):void {
    const TIMER_LENGTH = 10000
    if (id === this.id) {
      this.timer = setTimeout(() => {
        this.$bvModal.hide(this.id)
        this.goToIndexPage()
      }, TIMER_LENGTH)
    }
  }

  stopTimer(bvEvent: Event, id: string):void {
    if (id === this.id && this.timer !== null) {
      clearInterval(this.timer)
    }
  }

  goToIndexPage() {
    this.$router.push({
      name: RouteNames.index
    })
  }
}
